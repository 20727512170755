/* overriding default Braze Content Card Drawer Styles */
.ab-feed {
  background-color: #f9fcff !important;
}

.ab-feed .ab-feed-buttons-wrapper {
  background-color: #2c9ede !important;
}

/* Hide zendesk widget when we print the page */
@media print {
  .zendesk-container {
    display: none;
  }
}

/* Forms report printing : new page for each talent response */
@media print {
  .forms_pagebreak {
    page-break-before: always;
  }
}

/**===============================================
                    OVERFLOW
================================================*/
.cn_overflow-auto {
  overflow: auto;
}

.cn_overflow-hidden {
  overflow: hidden;
}

.cn_overflow-clip {
  overflow: clip;
}

.cn_overflow-visible {
  overflow: visible;
}

.cn_overflow-scroll {
  overflow: scroll;
}

.cn_overflow-x-auto {
  overflow-x: auto;
}

.cn_overflow-y-auto {
  overflow-y: auto;
}

.cn_overflow-x-hidden {
  overflow-x: hidden;
}

.cn_overflow-y-hidden {
  overflow-y: hidden;
}

.cn_overflow-x-clip {
  overflow-x: clip;
}

.cn_overflow-y-clip {
  overflow-y: clip;
}

.cn_overflow-x-visible {
  overflow-x: visible;
}

.cn_overflow-y-visible {
  overflow-y: visible;
}

.cn_overflow-x-scroll {
  overflow-x: scroll;
}

.cn_overflow-y-scroll {
  overflow-y: scroll;
}

/**===============================================
                    TYPOGRAPHY
================================================*/
.cn_capitalize {
  text-transform: capitalize;
}

.cn_decoration-none {
  text-decoration: none;
}

.cn_truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cn_line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cn_wrap-text {
  white-space: normal;
  word-wrap: break-word;
  overflow: auto;
}

.cn_word_break-break {
  word-break: break-word;
}

.cn_white-space-pre-wrap {
  white-space: pre-wrap;
}

.cn_no-wrap-text {
  white-space: nowrap;
  word-wrap: normal;
}

.cn_line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
}

.cn_text-start {
  text-align: start;
}

.cn_text-center {
  text-align: center;
}

.cn_line-through {
  text-decoration: line-through;
}
.cn_uppercase {
  text-transform: uppercase;
}

/**===============================================
                     DISPLAY
================================================*/
.cn_block {
  display: block;
}

.cn_inline-block {
  display: inline-block;
}

.cn_hidden {
  display: none;
}

.cn_inline-flex {
  display: inline-flex;
  align-items: center;
}

.cn_flex_algin_self_center {
  align-self: center;
}

/* Visible to screen readers only, use this instead of display: none */
.cn_sr_only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.cn_invisible {
  visibility: hidden;
}

.cn_visible {
  visibility: visible;
}

/**===============================================
                     POSITION
================================================*/
.cn_relative {
  position: relative;
}

.cn_absolute {
  position: absolute;
}

.cn_sticky {
  position: sticky;
}

.cn_fixed {
  position: fixed;
}

.cn_top-0 {
  top: 0;
}

/**===============================================
                     SIZING
================================================*/
.cn_w-full {
  width: 100%;
}

.cn_w-1\/2 {
  width: 50%;
}

.cn_w-1\/3 {
  width: 33%;
}

.cn_w-2\/3 {
  width: 66.6666667%;
}

.cn_w-min {
  width: min-content;
}

.cn_w-max {
  width: max-content;
}

.cn_w-auto {
  width: auto;
}

.cn_w-6 {
  width: 1.5rem;
}

.cn_w-12 {
  width: 3rem;
}

.cn_w-16 {
  width: 4rem;
}

.cn_w-20 {
  width: 5rem;
}

.cn_w-40 {
  width: 10rem;
}

.cn_w-64 {
  width: 16rem;
}

.cn_max-w-md {
  max-width: 32rem;
}

.cn_max-w-lg {
  max-width: 75rem;
}

.cn_max-w-2xl {
  max-width: 42rem;
}

.cn_max-w-24 {
  max-width: 6rem;
}

.cn_max-w-48 {
  max-width: 12rem;
}

.cn_max-w-84 {
  max-width: 21rem;
}

.cn_max-w-90 {
  max-width: 22.5rem;
}

.cn_max-w-96 {
  max-width: 24rem;
}

.cn_max-w-max {
  max-width: max-content;
}

.cn_w-min-auto {
  min-width: auto;
}

.cn_h-min {
  height: min-content;
}

.cn_h-min-10 {
  min-height: 2.5rem;
}

.cn_h-min-72 {
  min-height: 18rem;
}

.cn_h-min-240 {
  min-height: 60rem;
}

.cn_h-8 {
  height: 2rem;
}

.cn_h-10 {
  height: 2.5rem;
}

.cn_h-22 {
  height: 5.5rem;
}

.cn_h-4\/5 {
  height: 80%;
}

.cn_h-full {
  height: 100%;
}

.cn_h-max {
  height: max-content;
}

.cn_h-auto {
  height: auto;
}

.cn_max-h-3 {
  max-height: 0.75rem;
}

.cn_max-h-8 {
  max-height: 2rem;
}

.cn_max-h-10 {
  max-height: 2.5rem;
}

.cn_h-3 {
  height: 0.75rem;
}

.cn_h-4 {
  height: 1rem;
}

.cn_h-5 {
  height: 1.25rem;
}

.cn_h-6 {
  height: 1.5rem;
}

.cn_h-120 {
  height: 30rem;
}

.cn_object-contain {
  object-fit: contain;
}

/**===============================================
                    MARGINS
================================================*/
.cn_m-auto {
  margin: auto;
}

.cn_m-0-auto {
  margin: 0 auto;
}

.cn_m-auto-0 {
  margin: auto 0;
}

.cn_m-0 {
  margin: 0;
}

.cn_m-1 {
  margin: 0.25rem;
}

.cn_m-2 {
  margin: 0.5rem;
}

.cn_m-3 {
  margin: 0.75rem;
}

.cn_m-4 {
  margin: 1rem;
}

.cn_m-5 {
  margin: 1.25rem;
}

.cn_m-6 {
  margin: 1.5rem;
}

.cn_m-7 {
  margin: 1.75rem;
}

.cn_m-8 {
  margin: 2rem;
}

.cn_m-28 {
  margin: 7rem;
}

.cn_mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.cn_mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.cn_mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.cn_mx-2-negative {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.cn_mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.cn_mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.cn_mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.cn_mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.cn_mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.cn_mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.cn_my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.cn_my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.cn_my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.cn_my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.cn_my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cn_my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.cn_my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.cn_my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.cn_my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cn_mr-1 {
  margin-right: 0.25rem;
}

.cn_mr-2 {
  margin-right: 0.5rem;
}

.cn_mr-3 {
  margin-right: 0.75rem;
}

.cn_mr-4 {
  margin-right: 1rem;
}

.cn_mr-5 {
  margin-right: 1.25rem;
}

.cn_mr-6 {
  margin-right: 1.5rem;
}

.cn_mr-7 {
  margin-right: 1.75rem;
}

.cn_mr-8 {
  margin-right: 2rem;
}

.cn_mr-28 {
  margin-right: 7rem;
}

.cn_ml-1 {
  margin-left: 0.25rem;
}

.cn_ml-2 {
  margin-left: 0.5rem;
}

.cn_ml-3 {
  margin-left: 0.75rem;
}

.cn_ml-4 {
  margin-left: 1rem;
}

.cn_ml-5 {
  margin-left: 1.25rem;
}

.cn_ml-6 {
  margin-left: 1.5rem;
}

.cn_ml-7 {
  margin-left: 1.75rem;
}

.cn_ml-8 {
  margin-left: 2rem;
}

.cn_ml-28 {
  margin-left: 7rem;
}

.cn_mt-1 {
  margin-top: 0.25rem;
}

.cn_mt-2 {
  margin-top: 0.5rem;
}

.cn_mt-3 {
  margin-top: 0.75rem;
}

.cn_mt-4 {
  margin-top: 1rem;
}

.cn_mt-5 {
  margin-top: 1.25rem;
}

.cn_mt-6 {
  margin-top: 1.5rem;
}

.cn_mt-7 {
  margin-top: 1.75rem;
}

.cn_mt-8 {
  margin-top: 2rem;
}

.cn_mt-16 {
  margin-top: 4rem;
}

.cn_mt-28 {
  margin-top: 7rem;
}

.cn_mb-auto {
  margin-bottom: auto;
}

.cn_mb-0 {
  margin-bottom: 0rem;
}

.cn_mb-1 {
  margin-bottom: 0.25rem;
}

.cn_mb-2 {
  margin-bottom: 0.5rem;
}

.cn_mb-2-negative {
  margin-bottom: -0.5rem;
}

.cn_mb-3 {
  margin-bottom: 0.75rem;
}

.cn_mb-4 {
  margin-bottom: 1rem;
}

.cn_mb-5 {
  margin-bottom: 1.25rem;
}

.cn_mb-6 {
  margin-bottom: 1.5rem;
}

.cn_mb-7 {
  margin-bottom: 1.75rem;
}

.cn_mb-8 {
  margin-bottom: 2rem;
}

.cn_mb-16 {
  margin-bottom: 4rem;
}

.cn_mb-28 {
  margin-bottom: 7rem;
}

/**===============================================
                  FLEX-WRAP
================================================*/

.cn_fw-nw {
  flex-wrap: nowrap;
}

/**=============================================
                    Misc
================================================*/
.cn_border-none {
  border: none;
}

.cn_border .cn_outline-none {
  outline: none;
}

.cn_shadow-md {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 3px 3px rgba(0, 0, 0, 0.14);
}

.cn_gradient-border-blue {
  border-image: linear-gradient(to right, #0090d6, #000000) 5 0 0 0 stretch;
  border-image-slice: 1;
}

.cn_gradient-bg-blue {
  background: linear-gradient(to right, #0090d6, #000000);
}

.cn_gradient-bg-thrive-plus-transparent {
  background: var(--thrive-plus-background-gradient);
}

.cn_gradient-bg-thrive-plus-solid {
  background: var(--thrive-plus-gradient);
}

.cn_gradient-header-thrive-plus {
  background: var(--thrive-plus-gradient);
  background-clip: text;
}

.cn_tracking-widest {
  /* SEE THRIVE CARDS FOR REFERENCE */
  letter-spacing: 0.5rem;
}
/**=============================================
                  ATOM level classes!
These are "atom" level classes and should only be used by atoms or molecules
If you need something like this you can make an atom or molecule and pass in a prop ("small" for example)
================================================
 */

/**===============================================
                  FLEX-GROW
================================================*/
.cn_atom_grow-0 {
  flex-grow: 0;
}

.cn_atom_grow-1 {
  flex-grow: 1;
}

.cn_atom_grow-2 {
  flex-grow: 2;
}

.cn_atom_grow-3 {
  flex-grow: 3;
}

.cn_atom_grow-4 {
  flex-grow: 4;
}

/**===============================================
                  z-index
================================================*/
.cn_atom_zIndex-0 {
  z-index: 0 ;
}

.cn_atom_zIndex-1 {
  z-index: 1 ;
}

.cn_atom_zIndex-2 {
  z-index: 2 ;
}

.cn_atom_zIndex-3 {
  z-index: 3 ;
}

.cn_atom_zIndex-4 {
  z-index: 4 ;
}

/**===============================================
                  FLEX-SHRINK
================================================*/
.cn_atom_shrink-0 {
  flex-shrink: 0;
}

.cn_atom_shrink-1 {
  flex-shrink: 1;
}

.cn_atom_shrink-2 {
  flex-shrink: 2;
}

.cn_atom_shrink-3 {
  flex-shrink: 3;
}

.cn_atom_shrink-4 {
  flex-shrink: 4;
}

/**===============================================
                  FLEX-GAP
================================================*/
.cn_atom_gap-0 {
  gap: 0;
}

.cn_atom_gap-1 {
  gap: 0.25rem;
}

.cn_atom_gap-2 {
  gap: 0.5rem;
}

.cn_atom_gap-3 {
  gap: 0.75rem;
}

.cn_atom_gap-4 {
  gap: 1rem;
}

.cn_atom_gap-5 {
  gap: 1.25rem;
}

.cn_atom_gap-6 {
  gap: 1.5rem;
}

.cn_atom_gap-7 {
  gap: 1.75rem;
}

.cn_atom_gap-8 {
  gap: 2rem;
}

.cn_atom_gap-x-0 {
  column-gap: 0;
}

.cn_atom_gap-x-1 {
  column-gap: 0.25rem;
}

.cn_atom_gap-x-2 {
  column-gap: 0.5rem;
}

.cn_atom_gap-x-3 {
  column-gap: 0.75rem;
}

.cn_atom_gap-x-4 {
  column-gap: 1rem;
}

.cn_atom_gap-y-0 {
  row-gap: 0;
}

.cn_atom_gap-y-1 {
  row-gap: 0.25rem;
}

.cn_atom_gap-y-2 {
  row-gap: 0.5rem;
}

.cn_atom_gap-y-3 {
  row-gap: 0.75rem;
}

.cn_atom_gap-y-4 {
  row-gap: 1rem;
}

/**===============================================
                    PADDING
================================================*/
.cn_atom_p-0 {
  padding: 0;
}

.cn_atom_p-1 {
  padding: 0.25rem;
}

.cn_atom_p-2 {
  padding: 0.5rem;
}

.cn_atom_p-3 {
  padding: 0.75rem;
}

.cn_atom_p-4 {
  padding: 1rem;
}

.cn_atom_p-6 {
  padding: 1.5rem;
}

/* use this to compensate for the padding added by the
   bootstrap grid system's Col component
*/
.cn_mx-bootstrap-col-negative-offset {
  margin-right: -15px;
  margin-left: -15px;
}

.cn_atom_px-0 {
  padding-left: 0;
  padding-right: 0;
}

.cn_atom_px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.cn_atom_px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.cn_atom_px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.cn_atom_px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.cn_atom_px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.cn_atom_px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.cn_atom_px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.cn_atom_px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.cn_atom_py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.cn_atom_py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.cn_atom_py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.cn_atom_py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.cn_atom_py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.cn_atom_py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.cn_atom_py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.cn_atom_pt-0 {
  padding-top: 0;
}

.cn_atom_pt-1 {
  padding-top: 0.25rem;
}

.cn_atom_pt-2 {
  padding-top: 0.5rem;
}

.cn_atom_pt-3 {
  padding-top: 0.75rem;
}

.cn_atom_pt-4 {
  padding-top: 1rem;
}

.cn_atom_pt-5 {
  padding-top: 1.25rem;
}

.cn_atom_pt-6 {
  padding-top: 1.5rem;
}

.cn_atom_pr-0 {
  padding-right: 0;
}

.cn_atom_pr-1 {
  padding-right: 0.25rem;
}

.cn_atom_pr-2 {
  padding-right: 0.5rem;
}

.cn_atom_pr-3 {
  padding-right: 0.75rem;
}

.cn_atom_pr-4 {
  padding-right: 1rem;
}

.cn_atom_pr-5 {
  padding-right: 1.25rem;
}

.cn_atom_pr-6 {
  padding-right: 1.5rem;
}

.cn_atom_pb-0 {
  padding-bottom: 0;
}

.cn_atom_pb-1 {
  padding-bottom: 0.25rem;
}

.cn_atom_pb-2 {
  padding-bottom: 0.5rem;
}

.cn_atom_pb-3 {
  padding-bottom: 0.75rem;
}

.cn_atom_pb-4 {
  padding-bottom: 1rem;
}

.cn_atom_pb-5 {
  padding-bottom: 1.25rem;
}

.cn_atom_pb-6 {
  padding-bottom: 1.5rem;
}

.cn_atom_pb-7 {
  padding-bottom: 1.75rem;
}

.cn_atom_pb-8 {
  padding-bottom: 2rem;
}

.cn_atom_pb-16 {
  padding-bottom: 4rem;
}

.cn_atom_pl-0 {
  padding-left: 0;
}

.cn_atom_pl-1 {
  padding-left: 0.25rem;
}

.cn_atom_pl-2 {
  padding-left: 0.5rem;
}

.cn_atom_pl-3 {
  padding-left: 0.75rem;
}

.cn_atom_pl-4 {
  padding-left: 1rem;
}

.cn_atom_pl-7 {
  padding-left: 2rem;
}

/**===============================================
                  BORDER-RADIUS
================================================*/
.cn_atom_rounded-none {
  border-radius: 0rem;
}

.cn_atom_rounded-xs {
  border-radius: 0.125rem;
}

.cn_atom_rounded-sm {
  border-radius: 0.25rem;
}

.cn_atom_rounded-md {
  border-radius: 0.375rem;
}

.cn_atom_rounded-lg {
  border-radius: 0.5rem;
}

.cn_atom_rounded-xl {
  border-radius: 0.75rem;
}

.cn_atom_rounded-2xl {
  border-radius: 1rem;
}

.cn_atom_rounded-3xl {
  border-radius: 1.5rem;
}

.cn_atom_rounded-full {
  border-radius: 9999px;
}

/**===============================================
                POINTER-EVENTS
================================================*/
.cn_pointer-events-none {
  pointer-events: none;
}

.cn_pointer-events-auto {
  pointer-events: auto;
}

/**===============================================
                  BORDER-STYLE
================================================*/

.cn_atom_border-none {
  border-style: none;
}

.cn_atom_border-solid {
  border-style: solid;
}

.cn_atom_border-dotted {
  border-style: dotted;
}

/**
Responsive design with tailwind-like classnames
Docs for responsive design with tailwind-like classnames: https://tailwindcss.com/docs/responsive-design
 */

@media screen and (min-width: 769px) {
  /**===============================================
                     DISPLAY
  ================================================*/
  .cn_md\:block {
    display: block;
  }

  .cn_md\:inline-block {
    display: inline-block;
  }

  .cn_md\:hidden {
    display: none;
  }
  /**===============================================
                     POSITION
  ================================================*/

  /**===============================================
                     SIZING
  ================================================*/

  .cn_md\:w-auto {
    width: auto;
  }

  .cn_md\:w-full {
    width: 100%;
  }

  .cn_md\:h-72 {
    height: 18rem;
  }

  .cn_md\:w-2\/3 {
    width: 66.6666667%;
  }

  .cn_md\:w-20 {
    width: 5rem;
  }

  .cn_md\:w-32 {
    width: 8rem;
  }

  .cn_md\:w-48 {
    width: 12rem;
  }

  .cn_md\:w-96 {
    width: 24rem;
  }

  /**===============================================
                     Margins
  ================================================*/
  .cn_md\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .cn_md\:mt-0 {
    margin-top: 0;
  }

  .cn_md\:mb-4 {
    margin-bottom: 1rem;
  }

  .cn_md\:mb-0 {
    margin-bottom: 0rem;
  }

  .cn_md\:ml-2 {
    margin-left: 0.5rem;
  }

  .cn_md\:ml-4 {
    margin-left: 1rem;
  }

  .cn_md\:mr-3 {
    margin-right: 0.75rem;
  }

  .cn_md\:mr-4 {
    margin-right: 1rem;
  }

  .cn_md\:mr-8 {
    margin-right: 2rem;
  }

  .cn_md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .cn_md\:max-w-124 {
    max-width: 31.25rem;
  }

  /**===============================================
                     ATOM level classes!
  ================================================*/

  .cn_atom_md\:pb-0 {
    padding-bottom: 0;
  }

  .cn_atom_md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 1201px) {
  .cn_lg\:block {
    display: block;
  }

  .cn_lg\:inline-block {
    display: inline-block;
  }

  .cn_lg\:hidden {
    display: none;
  }

  .cn_lg\:w-auto {
    width: auto;
  }

  .cn_lg\:w-full {
    width: 100%;
  }
}

@media screen and (min-width: 1441px) {
  .cn_xl\:block {
    display: block;
  }

  .cn_xl\:hidden {
    display: none;
  }
}

/**===============================================
                  AG GRID
================================================*/

.cn_ag-h45 {
  height: 45px;
}

/**===============================================
                  CURSOR
================================================*/

.cn_cursor-pointer {
  cursor: pointer;
}

/**===============================================
                  Gradient variables
================================================*/
:root {
  --thrive-plus-gradient: linear-gradient(
    90deg,
    #945dc9 24%,
    #8f5ec8 31%,
    #8361c8 39%,
    #6f66c8 47%,
    #536dc8 55%,
    #2e76c8 62%,
    #0282c8 70%,
    #0083c8 71%,
    #0081c5 78%,
    #007bbd 83%,
    #0070af 86%,
    #00629b 90%,
    #004f81 93%,
    #003861 95%,
    #001d3c 98%,
    #000014 100%
  );

  --thrive-plus-background-gradient: linear-gradient(
    90deg,
    rgba(148, 93, 201, 0.2) 24%,
    rgba(143, 94, 200, 0.2) 31%,
    rgba(131, 97, 200, 0.2) 39%,
    rgba(111, 102, 200, 0.2) 47%,
    rgba(83, 109, 200, 0.2) 55%,
    rgba(46, 118, 200, 0.2) 62%,
    rgba(2, 130, 200, 0.2) 70%,
    rgba(0, 131, 200, 0.2) 71%,
    rgba(0, 129, 197, 0.2) 78%,
    rgba(0, 123, 189, 0.2) 83%,
    rgba(0, 112, 175, 0.2) 86%,
    rgba(0, 98, 155, 0.2) 90%,
    rgba(0, 79, 129, 0.2) 93%,
    rgba(0, 56, 97, 0.2) 95%,
    rgba(0, 29, 60, 0.2) 98%,
    rgba(0, 0, 20, 0.2) 100%
  );
}
